<template>
  <div></div>
</template>

<script>
// 対応している認証先
const ACCEPT_PROVIDER = ['google']

export default {
  name: 'account',
  async mounted () {
    if (!this.existInvitationSetting) await this.$store.dispatch('settingInvitation/getInvitationSetting')
    if (this.invitationLimited) {
      this.$store.commit('setTelop', { show: true, msg: 'アカウント登録は招待限定です', type: 'warning' })
      this.$router.push({ name: 'top' })
      return
    }

    if (this.referrerDomains.length <= 0) await this.$store.dispatch('loginReferrer/getLoginReferrer')
    const referrerDomain = document.referrer ? new URL(document.referrer).hostname : ''
    const isAcceptedReferrer = this.referrerDomains.includes(referrerDomain)
    const provider = this.$route.params.provider
    const isAcceptProvider = ACCEPT_PROVIDER.includes(provider)
    const isNotAuth = !this.uid && !this.isAuthProcessing

    if (!isAcceptProvider) {
      this.$router.push({ name: 'notfound' })
      return
    }

    if (!isAcceptedReferrer) {
      this.$store.commit('setTelop', { show: true, msg: '遷移元はアカウント登録を承認されていません', type: 'warning' })
      this.$router.push({ name: 'top' })
      return
    }

    if (this.uid) {
      this.$router.push({ name: 'home' })
      return
    }

    if (isAcceptProvider && isNotAuth && isAcceptedReferrer) {
      this.$store.commit('setSubmitting', true)
      sessionStorage.setItem('isSignupUser', true)
      this.$store.dispatch('auth/signin', provider)
    }
  },
  computed: {
    /**
     * @return {Boolean} 認証処理中かどうか
     */
    isAuthProcessing () {
      return this.$store.getters.isAuthProcessing
    },
    /**
     * @return {String} ユーザーID
     */
    uid () {
      return this.$store.getters['auth/uid']
    },
    /**
     * @return {String[]} 許可されたreferrerのdomain一覧
     */
    referrerDomains () {
      return this.$store.getters['loginReferrer/domains']
    },
    /**
     * @return {Boolean} 招待機能情報の存在有無
     */
    existInvitationSetting () {
      return this.$store.getters['settingInvitation/existSetting']
    },
    /**
     * @return {Boolean} 招待機能を利用し入会ユーザを招待限定とするか
     */
    invitationLimited () {
      return this.$store.getters['settingInvitation/limited']
    }
  }
}
</script>
